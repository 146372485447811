var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header"},[_c('Heading',{staticClass:"reserved-area-titles title",attrs:{"tag":"h1","customClass":"page-title","label":_vm.$t('pages.personalPage.notifications.title')}})],1),_c('div',{staticClass:"content"},[(
        (_vm.getPriceDropNotifications && _vm.getPriceDropNotifications.length > 0) ||
        (_vm.getCarAlertNotifications && _vm.getCarAlertNotifications.length > 0)
      )?_c('div',[(_vm.getCarAlertNotifications && _vm.getCarAlertNotifications.length > 0)?_c('Subtitle',{staticClass:"notifications-section-title",attrs:{"weight":"semibold","label":_vm.$t('pages.personalPage.notifications.carAlertSubtitle')}}):_vm._e(),(_vm.getCarAlertNotifications && _vm.getCarAlertNotifications.length > 0)?_c('div',_vm._l((_vm.processedCarAlertNotifications),function(notification,index){return _c('CarAlertCard',{key:`car-alert-card-${index}`,attrs:{"notification":notification},on:{"onToggle":_vm.handleCarAlertToggle,"closeCard":_vm.handleCarAlertDelete}})}),1):_vm._e(),(
          _vm.getPriceDropNotifications && _vm.getPriceDropNotifications.length > 0
        )?_c('Subtitle',{staticClass:"notifications-section-title",attrs:{"weight":"semibold","label":_vm.$t('pages.personalPage.notifications.priceDropSubtitle')}}):_vm._e(),(
          _vm.getPriceDropNotifications && _vm.getPriceDropNotifications.length > 0
        )?_c('div',_vm._l((_vm.getPriceDropNotifications),function(notification,index){return _c('PriceDropCard',{key:`price-drop-card-${index}`,attrs:{"notification":notification},on:{"onToggle":_vm.handlePriceDropToggle,"closeCard":_vm.handlePriceDropDelete}})}),1):_vm._e()],1):_c('div',[_c('NoResults',{staticClass:"mt-10",attrs:{"title":_vm.$t('pages.personalPage.notifications.emptyNotifications'),"description":_vm.$t('pages.personalPage.notifications.description'),"labelButton":_vm.$t('pages.personalPage.notifications.button')},on:{"onClick":_vm.onClickNoResultsHandler}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }