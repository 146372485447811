<template>
  <div>
    <div class="header">
      <Heading
        tag="h1"
        customClass="page-title"
        class="reserved-area-titles title"
        :label="$t('pages.personalPage.notifications.title')"
      ></Heading>
    </div>
    <div class="content">
      <div
        v-if="
          (getPriceDropNotifications && getPriceDropNotifications.length > 0) ||
          (getCarAlertNotifications && getCarAlertNotifications.length > 0)
        "
      >
        <Subtitle
          class="notifications-section-title"
          weight="semibold"
          :label="$t('pages.personalPage.notifications.carAlertSubtitle')"
          v-if="getCarAlertNotifications && getCarAlertNotifications.length > 0"
        />
        <div
          v-if="getCarAlertNotifications && getCarAlertNotifications.length > 0"
        >
          <CarAlertCard
            v-for="(notification, index) in processedCarAlertNotifications"
            :key="`car-alert-card-${index}`"
            :notification="notification"
            @onToggle="handleCarAlertToggle"
            @closeCard="handleCarAlertDelete"
          />
        </div>
        <Subtitle
          class="notifications-section-title"
          weight="semibold"
          :label="$t('pages.personalPage.notifications.priceDropSubtitle')"
          v-if="
            getPriceDropNotifications && getPriceDropNotifications.length > 0
          "
        />
        <div
          v-if="
            getPriceDropNotifications && getPriceDropNotifications.length > 0
          "
        >
          <PriceDropCard
            v-for="(notification, index) in getPriceDropNotifications"
            :key="`price-drop-card-${index}`"
            :notification="notification"
            @onToggle="handlePriceDropToggle"
            @closeCard="handlePriceDropDelete"
          />
        </div>
      </div>
      <div v-else>
        <NoResults
          class="mt-10"
          :title="$t('pages.personalPage.notifications.emptyNotifications')"
          :description="$t('pages.personalPage.notifications.description')"
          :labelButton="$t('pages.personalPage.notifications.button')"
          @onClick="onClickNoResultsHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Subtitle,
  PriceDropCard,
  CarAlertCard,
  Heading,
} from "@sc/ds-ui-component-library";
import { NoResults } from "../../../organisms";
import { mapGetters } from "vuex";
import { getPriceFormatted } from "../../../../utils/currency";

export default {
  components: {
    Heading,
    NoResults,
    Subtitle,
    PriceDropCard,
    CarAlertCard,
  },
  methods: {
    onClickNoResultsHandler() {
      this.$router.push({
        name: "SearchPage",
      });
    },
    handleCarAlertToggle(id) {
      this.$store.dispatch(
        "NotificationModule/activateCarAlertNotification",
        id
      );
    },
    handlePriceDropToggle(vin) {
      this.$store.dispatch(
        "NotificationModule/activatePriceDropNotification",
        vin
      );
    },
    handleCarAlertDelete(id) {
      this.$store.dispatch("NotificationModule/deleteCarAlertNotification", id);
    },
    handlePriceDropDelete(vin) {
      this.$store.dispatch(
        "NotificationModule/deletePriceDropNotification",
        vin
      );
    },
    mapTags(search) {
      const dropdowns = this.getDropdowns;
      const composedTags = {
        monthlyDeposit: { monthlyPayment: "", depositValue: "" },
        price: { priceInf: "", priceSup: "" },
        km: { kmInf: "", kmSup: "" },
        year: { yearInf: "", yearSup: "" },
      };
      const composedFilters = {
        kmInf: "km",
        kmSup: "km",
        yearInf: "year",
        yearSup: "year",
        priceInf: "price",
        priceSup: "price",
        monthlyPayment: "monthlyDeposit",
        depositValue: "monthlyDeposit",
      };
      const currencyTags = ["price", "monthlyDeposit"];

      const filters = JSON.parse(search.filters).filters;
      const needle = filters.needle;

      var p;
      var c;
      var mappedTags = [
        ...Object.entries(filters)
          .map((f) => {
            return f[1].map((id) => {
              var value = dropdowns[f[0] === "brand" ? "brands" : f[0]]?.find(
                (d) => d.key == id
              ).value;
              if (f[0] === "brand") {
                c = dropdowns.brands.find((brd) => brd.key == id).children;
              }

              if (f[0] === "model") {
                p = filters.brand
                  .map((b) => dropdowns.brands.find((brd) => brd.key == b))
                  .filter((prt) => !!prt.children.find((m) => m.key == id));
                p = { ...p[0], type: "brands" };
                value = p.children.find((m) => m.key == id).value;
              }
              var parent = composedFilters[f[0]];
              if (parent) composedTags[parent][f[0]] = id;
              return {
                children: c,
                parent: p,
                id: parent ? composedTags[parent] : Number(id),
                type: f[0] === "brand" ? "brands" : parent ? parent : f[0],
                value: parent
                  ? Object.values(composedTags[parent]).reduce((acc, val) =>
                      this.$t(`searchform.tags.${parent}`, {
                        value1: currencyTags.includes(parent)
                          ? getPriceFormatted(acc)
                          : acc,
                        value2: currencyTags.includes(parent)
                          ? getPriceFormatted(val)
                          : val,
                      })
                    )
                  : value,
              };
            });
          })
          .flat(),
      ];
      if (needle)
        mappedTags = [
          ...mappedTags,
          {
            id: 0,
            type: "needle",
            value: needle || "",
          },
        ];
      const simple = mappedTags.filter(
        (tag) => !Object.keys(composedTags).includes(tag.type)
      );
      const composed = [];
      Object.keys(composedTags).forEach((t) => {
        const filtered = mappedTags.filter((tag) => tag.type === t);
        if (filtered.length > 0) composed.push(filtered[filtered.length - 1]);
      });
      return composed.concat(simple);
    },
  },
  computed: {
    ...mapGetters({
      getPriceDropNotifications: "NotificationModule/getPriceDropNotifications",
      getCarAlertNotifications: "NotificationModule/getCarAlertNotifications",
      getDropdowns: "SearchModule/dropdown/getDropdowns",
    }),
    processedCarAlertNotifications() {
      return this.getCarAlertNotifications.map((notification) => {
        return { ...notification, tags: this.mapTags(notification) };
      });
    },
  },
  mounted() {
    this.$store.dispatch("NotificationModule/fetchPriceDropNotifications");
    this.$store.dispatch("NotificationModule/fetchCarAlertNotifications");
  },
};
</script>

<style lang="css" scoped>
.saved-search {
  margin-bottom: 24px;
}

.header {
  border-bottom: 2px solid #e4ecf0;
  padding-bottom: 1.15rem;
}

.content {
  margin-top: 1rem;
}

.page-title {
  font-size: 24px;
}

.reserved-area-titles {
  --heading-xlarge-font-size: 24px;
  --heading-xlarge-line-height: 31px;
  --heading-color: var(--theme-color-secondary);
  --heading-xlarge-weight: 700;
  text-transform: uppercase;
}

.notifications-section-title {
  margin-top: 20px;
  margin-bottom: 20px;
  --subtitle-color: var(--theme-color);
}

::v-deep .notification-card {
  margin-bottom: 15px;
}
</style>
